import { ReactNode } from 'react';
import { user as UserType } from '@fountain/wx-api-clients/generated/security';
import { WorkerTaskFlow } from '@/models/api/todo/taskFlowsProcesses';

export type PredefinedUserType = UserType & {
  isPredefinedWorker?: boolean;
};

export const WORKER_BASE_PATH = import.meta.env.VITE_WORKER_BASE_PATH || '';
export const WORKER_BASE_URL = import.meta.env.VITE_WORKER_BASE_URL || '';

export const PREDEFINED_AUTH_WORKER: PredefinedUserType = {
  isPredefinedWorker: true,
  status: 'ok',
  uuid: 'e787a3c5-22b7-4df2-9f8a-5a2ea28325d2',
  firstname: 'John',
  lastname: 'Smith',
  email: 'john@smith.com',
  companyUuid: 'a5c637d6-47b1-4855-a7f2-045d4200ff1c',
  company: {
    uuid: 'a5c637d6-47b1-4855-a7f2-045d4200ff1c',
    name: 'Goat Farm',
    slug: 'goat-farm',
  },
  slug: 'john-smith-sewmxgu',
  audience: 'worker',
  audienceUuid: '1f492473-8468-4780-a42c-1d2e9e34c374',
  createdAt: '2023-06-05T17:19:38.217Z',
  lastUpdatedAt: '2023-06-05T17:19:38.461Z',
  createdBy: '5318120a-8168-47ed-9e67-b0e60320e54a',
  lastUpdatedBy: '5318120a-8168-47ed-9e67-b0e60320e54a',
};

export enum HireApplicantSource {
  FOUNTAIN_HIRE = 'Fountain Hire',
  FOUNTAIN_HIRE_PAPI = 'Fountain Hire PAPI',
}

export const WORKDAY_APPLICANT_SOURCE = 'workday';

export const STATUS_COLORS = {
  success: 'green',
  neutral: 'neutral',
  caution: 'yellow',
  warning: 'amber',
  danger: 'rose',
} as const;

export type StatusColor = (typeof STATUS_COLORS)[keyof typeof STATUS_COLORS];

export enum Feature {
  ONBOARD = 'onboard',
  COMPLIANCE = 'compliance',
  COMPLIANCE_V2 = 'compliancev2',
  PULSE = 'pulse',
  REFERRAL = 'referral',
}

export type FeatureTask = {
  feature: Feature;
  icon?: React.ElementType;
  uuid?: string;
  header: string;
  description?: string;
  estimate?: string;
  status: {
    label: string | ReactNode;
    color?: StatusColor;
    sortingOrder: number;
  };
  subHeader?: ReactNode;
  // continueUrl is used in WelcomeHeader component so that clicking the continue button will take
  // them directly to a specific page (e.g. take user directly to onboard task page instead of task flow page)
  continueUrl?: string;
  onClick?: (redirectToNextTaskFlowTaskToDo?: boolean) => void;
  isComplete: boolean;
};

export type TaskFlowStatus = NonNullable<
  WorkerTaskFlow['assignedTaskFlowStatus']
>;
