import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './router/worker';
import { WORKER_BASE_PATH } from './utils/worker/constants';
import { NuqsAdapter } from 'nuqs/adapters/react-router/v6';

const router = createBrowserRouter(routes, {
  basename: WORKER_BASE_PATH,
});

function App() {
  return (
    <NuqsAdapter>
      <RouterProvider router={router} />
    </NuqsAdapter>
  );
}

export default App;
